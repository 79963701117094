import React, { useEffect } from 'react'
import "../../assets/scss/landing-page.css"

// import "./assets/scss/common-class.scss"
import hero1 from "../../assets/images/landingImg/hero-3.jpg";
import hero2 from "../../assets/images/landingImg/hero-2.jpg";
import hero3 from "../../assets/images/landingImg/hero-1.jpg";
import service1 from "../../assets/images/landingImg/ser-1.svg";
import service2 from "../../assets/images/landingImg/ser-2.svg";
import service3 from "../../assets/images/landingImg/ser-3.svg";
import service4 from "../../assets/images/landingImg/ser-4.svg";
import service5 from "../../assets/images/landingImg/ser-5.svg";
import service6 from "../../assets/images/landingImg/ser-6.svg";
import menuIconSvg from "../../assets/images/landingImg/menu-icon.svg";
import footerFb from "../../assets/images/landingImg/footer-fb.svg";
import footerTwit from "../../assets/images/landingImg/footer-twitter.svg";
import footerInsta from "../../assets/images/landingImg/footer-insta.svg";
import footerLinked from "../../assets/images/landingImg/footer-linkdin.svg";
import closeIco from "../../assets/images/landingImg/close-icon.svg";
import arrowsSvg from "../../assets/images/landingImg/arrows.svg";
import aboutImg from "../../assets/images/landingImg/about-img.jpg";
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../configs';
import 'slick-carousel/slick/slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import $ from 'jquery';

const TailorHomePage = () => {
    useEffect(() => {
        const $heroSlider = $('.hero-slider');
        $heroSlider.slick({
            dots: false,
            infinite: true,
            fade: true,
            autoplay: true,
            autoplaySpeed: 1500,
        });

        // Other event listeners for header and menu actions...
    }, []);
    const navigate = useNavigate()
    const navigateLoginPage = () => {
        return navigate(ROUTES.LOGIN)
    }

    return (

        <body>

            <header className="main-header w-100">
                <div className="container">
                    <div className="row justify-content-between align-items-center header-main">
                        <div className="col-lg-6 col-6">
                            <div className="text-white font-50 italianno-regular">Parth Tailor</div>
                        </div>
                        <div className="col-lg-3 col-6 d-flex justify-content-end">
                            <ul className="d-lg-flex justify-content-between mb-0 ps-0 menu-link w-100 d-none">
                                <li className="list-none">
                                    <a href="#home" className="text-decoration-none text-uppercase text-white">Home</a>
                                </li>
                                <li className="list-none">
                                    <a href="#about-us" className="text-decoration-none text-uppercase text-white">About</a>
                                </li>
                                <li className="list-none">
                                    <a href="#service" className="text-decoration-none text-uppercase text-white">Service</a>
                                </li>

                            </ul>

                            <div className="menu-toggle  d-block d-lg-none">
                                <div className="icons menu">
                                    <img src={menuIconSvg} alt="" className="w-100 h-100 p-2 menu-icon" />
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div className="res-header d-none">
                    <div className="container">
                        <div className="d-flex justify-content-between mt-5 align-items-center">
                            <div className="col-6">
                                <div className="text-white font-30 ">TAILOR</div>
                            </div>
                            <div className="col-6">
                                <div className="menu-toggle">
                                    <div className="icons close ms-auto">
                                        <img src={closeIco} alt="" className="w-100 h-100 p-2 close-icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="menu-link mt-5">
                            <ul className="mb-0 ps-0 w-100">
                                <li className="list-none">
                                    <a href="#home" className="text-decoration-none text-white">Home</a>
                                </li>
                                <li className="list-none">
                                    <a href="#about-us" className="text-decoration-none text-white">About</a>
                                </li>
                                <li className="list-none">
                                    <a href="#service" className="text-decoration-none text-white">Service</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div className='login-button-class'>
                    <button
                        type="button"
                        className="btn w-sm bg-white text-primary"
                        id="delete-record"
                        onClick={navigateLoginPage}
                    >
                        <svg className='me-2' width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M14 4L17.5 4C20.5577 4 20.5 8 20.5 12C20.5 16 20.5577 20 17.5 20H14M15 12L3 12M15 12L11 16M15 12L11 8" stroke="#192b3f" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        LOGIN
                    </button>
                </div>
            </header>
            <main>
                {/* <!-- Hero Section --> */}
                <section className="hero-slider-section position-relative overflow-hidden" id="home">
                    <div className="hero-slider">
                        <div className="hero-item position-relative">
                            <img src={hero1} alt="" className="w-100 h-100 object-cover position-absolute" />
                            <div className="bg-layer position-absolute w-100 h-100 bg-dark opacity-50"></div>
                            <div className="hero-content position-absolute top-center w-100">
                                <div className="container">
                                    <div className="col-lg-6">
                                        <div className="mb-3 text-white font-30 leading-15 res-font-15 fw-normal italianno-regular">Made to Measure Dress Shirts
                                        </div>
                                        <div
                                            className="font-50 fw-medium text-white font-ff leading-60 mb-3 res-font-30 res-leading-40">
                                            Discover true
                                            personalization.</div>
                                        <button
                                            className="bg-primary text-white d-flex align-items-center border-0 text-capitalize button font-ff font-13 res-font-15">
                                            create your shirt <img src={arrowsSvg} alt="" className="ms-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hero-item position-relative">
                            <img src={hero2} alt="" className="w-100 h-100 object-cover position-absolute" />
                            <div className="bg-layer position-absolute w-100 h-100 bg-dark opacity-50"></div>
                            <div className="hero-content position-absolute top-center w-100">
                                <div className="container">
                                    <div className="col-lg-6">
                                        <div className="mb-3 text-white font-30 leading-15 res-font-15 fw-normal italianno-regular">Made to Measure Dress Shirts
                                        </div>
                                        <div
                                            className="font-50 fw-medium text-white font-ff leading-60 mb-3 res-font-30 res-leading-40">
                                            Discover true
                                            personalization.</div>
                                        <button
                                            className="bg-primary text-white d-flex align-items-center border-0 text-capitalize button font-ff font-13 res-font-15">
                                            create your shirt <img src={arrowsSvg} alt="" className="ms-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hero-item position-relative">
                            <img src={hero3} alt="" className="w-100 h-100 object-cover position-absolute" />
                            <div className="bg-layer position-absolute w-100 h-100 bg-dark opacity-50"></div>
                            <div className="hero-content position-absolute top-center w-100">
                                <div className="container">
                                    <div className="col-lg-6">
                                        <div className="mb-3 text-white font-30 leading-15 res-font-15 fw-normal italianno-regular">Made to Measure Dress Shirts
                                        </div>
                                        <div
                                            className="font-50 fw-medium text-white font-ff leading-60 mb-3 res-font-30 res-leading-40">
                                            Discover true
                                            personalization.</div>
                                        <button
                                            className="bg-primary text-white d-flex align-items-center border-0 text-capitalize button font-ff font-13 res-font-15">
                                            create your shirt <img src={arrowsSvg} alt="" className="ms-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="spacing mt-5"></div>

                {/* <!-- About Section --> */}
                <section className="about-section overflow-hidden " id="about-us">
                    <div className="container px-p-0">
                        <div className="row d-flex align-items-center">
                            <div className="col-md-7 col-12 order-md-1 order-2 px-p-0 ">
                                <div className="about-img card-hover overflow-hidden">
                                    <img src={aboutImg} alt="" className="h-100 w-100 object-cover" />
                                </div>
                            </div>
                            <div className="col-md-5 col-12 mb-md-0 mb-md-3 mb-1 order-md-2 order-1">
                                <div className="px-p-p ps-xl-5">
                                    <div className="fw-bold font-50 text-primary mb-2 res-font-25 text-capitalize italianno-regular">Planning a
                                        Wedding?</div>
                                    <div className="font-ff opacity-75 fw-normal font-14 text-primary lh-base mb-3">Need to make sure your whole party is
                                        coordinated and looking good? We know it can be a bit of a hassle to keep track of
                                        everyone & everything.</div>
                                    <div className="font-ff opacity-75 fw-normal font-14 text-primary lh-base mb-2">With 'Piece of Cake', our wedding party suit
                                        coordinator, we take care of it all for you! You'll have your party members, outfits &
                                        event set up in a matter of minutes. Try it out!
                                    </div>
                                    <button
                                        className="bg-primary mt-4 text-white d-flex align-items-center border-0 text-capitalize button font-ff font-13 mb-4">
                                        About Us <img src={arrowsSvg} alt="" className="ms-2" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="spacing mt-md-5"></div>

                {/* <!-- Service Section --> */}
                <section className="service-section bg-primary" id="service">
                    <div className="container">
                        <div className="text-center">
                            <div
                                className="text-white text-capitalize italianno-regular font-50 text-center mb-5 fw-bold title d-inline-block pb-2 res-font-25">
                                Our Services
                            </div>
                        </div>
                        <div className="row rowX">
                            <div className="col-lg-4 col-sm-6 col-12">
                                <div className="service-card">
                                    <div className="col-12">
                                        <div className="ser-img mx-auto">
                                            <img src={service1} alt="" className="w-100 h-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4 text-center">
                                        <div className="text-white font-ff font-20 fw-medium mb-3">Thread and needle</div>
                                        <div className="text-white font-ff font-14 lh-base text-truncate text-truncate5 opacity-75 fw-normal">Lorem ipsum
                                            dolor,
                                            sit amet consectetur
                                            adipisicing elit. Porro
                                            in ullam odio cum dolorem dolores. Praesentium, exercitationem eum aut harum
                                            voluptas repellendus qui dignissimos sint modi repudiandae ad id recusandae!
                                            Beatae,
                                            expedita.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                                <div className="service-card overflow-hidden">
                                    <div className="col-12">
                                        <div className="ser-img mx-auto">
                                            <img src={service2} alt="" className="w-100 h-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4 text-center">
                                        <div className="text-white font-ff font-20 fw-medium mb-3">Thread and needle</div>
                                        <div className="text-white font-ff font-14 lh-base text-truncate text-truncate5 opacity-75 fw-normal">Lorem ipsum
                                            dolor,
                                            sit amet consectetur
                                            adipisicing elit. Porro
                                            in ullam odio cum dolorem dolores. Praesentium, exercitationem eum aut harum
                                            voluptas repellendus qui dignissimos sint modi repudiandae ad id recusandae!
                                            Beatae,
                                            expedita.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                                <div className="service-card overflow-hidden">
                                    <div className="col-12">
                                        <div className="ser-img mx-auto">
                                            <img src={service3} alt="" className="w-100 h-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4 text-center">
                                        <div className="text-white font-ff font-20 fw-medium mb-3">Thread and needle</div>
                                        <div className="text-white font-ff font-14 lh-base text-truncate text-truncate5 opacity-75 fw-normal">Lorem ipsum
                                            dolor,
                                            sit amet consectetur
                                            adipisicing elit. Porro
                                            in ullam odio cum dolorem dolores. Praesentium, exercitationem eum aut harum
                                            voluptas repellendus qui dignissimos sint modi repudiandae ad id recusandae!
                                            Beatae,
                                            expedita.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                                <div className="service-card overflow-hidden">
                                    <div className="col-12">
                                        <div className="ser-img mx-auto">
                                            <img src={service4} alt="" className="w-100 h-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4 text-center">
                                        <div className="text-white font-ff font-20 fw-medium mb-3">Thread and needle</div>
                                        <div className="text-white font-ff font-14 lh-base text-truncate text-truncate5 opacity-75 fw-normal">Lorem ipsum
                                            dolor,
                                            sit amet consectetur
                                            adipisicing elit. Porro
                                            in ullam odio cum dolorem dolores. Praesentium, exercitationem eum aut harum
                                            voluptas repellendus qui dignissimos sint modi repudiandae ad id recusandae!
                                            Beatae,
                                            expedita.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                                <div className="service-card overflow-hidden">
                                    <div className="col-12">
                                        <div className="ser-img mx-auto">
                                            <img src={service5} alt="" className="w-100 h-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4 text-center">
                                        <div className="text-white font-ff font-20 fw-medium mb-3">Thread and needle</div>
                                        <div className="text-white font-ff font-14 lh-base text-truncate text-truncate5 opacity-75 fw-normal">Lorem ipsum
                                            dolor,
                                            sit amet consectetur
                                            adipisicing elit. Porro
                                            in ullam odio cum dolorem dolores. Praesentium, exercitationem eum aut harum
                                            voluptas repellendus qui dignissimos sint modi repudiandae ad id recusandae!
                                            Beatae,
                                            expedita.</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12">
                                <div className="service-card overflow-hidden">
                                    <div className="col-12">
                                        <div className="ser-img mx-auto">
                                            <img src={service6} alt="" className="w-100 h-100" />
                                        </div>
                                    </div>
                                    <div className="col-12 mt-4 text-center">
                                        <div className="text-white font-ff font-20 fw-medium mb-3">Thread and needle</div>
                                        <div className="text-white font-ff font-14 lh-base text-truncate text-truncate5 opacity-75 fw-normal">Lorem ipsum
                                            dolor,
                                            sit amet consectetur
                                            adipisicing elit. Porro
                                            in ullam odio cum dolorem dolores. Praesentium, exercitationem eum aut harum
                                            voluptas repellendus qui dignissimos sint modi repudiandae ad id recusandae!
                                            Beatae,
                                            expedita.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer className="footer-section bg-primary">
                <div className="container">
                    <div className="col-md-6 col-12 mx-auto text-center">
                        <div className="text-white font-30 italianno-regular text-capitalize">TAILOR</div>
                        <div className="col-lg-4 col-sm-7 col-9 mx-auto mt-4">
                            <ul className="d-flex justify-content-between mb-0 ps-0 menu-link w-100 ">
                                <li className="list-none">
                                    <a href="" className="text-decoration-none text-white text-uppercase">Home</a>
                                </li>
                                <li className="list-none">
                                    <a href="#about-us" className="text-decoration-none text-white text-uppercase">About</a>
                                </li>
                                <li className="list-none">
                                    <a href="#service" className="text-decoration-none text-white text-uppercase">Service</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-5 col-sm-6 mx-auto mt-4 social-icon">
                            <div className="footer_icons w-100 d-flex  justify-content-center order-lg-0 order-1">
                                <img src={footerFb} alt="" className="m-lg-1 m-0 me-lg-0 me-2" />
                                <img src={footerTwit} alt="" className="m-lg-1 m-0 me-lg-0 me-2" />
                                <img src={footerInsta} alt="" className="m-lg-1 m-0 me-lg-0 me-2" />
                                <img src={footerLinked} alt="" className="m-lg-1 m-0 me-lg-0 me-2" />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


        </body>
    )
}

export default TailorHomePage