import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HELPER } from "../services";
import { ROUTES } from "../configs";

const Navdata = (props) => {
  const initialMenusStates = [
    {
      label: "Menu",
      isHeader: true,
    },
    // {
    //   id: "dashboard",
    //   label: "Dashboards",
    //   icon: "ri-dashboard-2-line",
    //   link: "/#",
    //   isActive: false,
    //   click: function (e) {
    //     e.preventDefault();
    //     // setIsDashboard(!isDashboard);
    //     // setIscurrentState("Dashboard");
    //     // updateIconSidebar(e);
    //     changeMenu("dashboard")
    //   },
    //   subItems: [
    {
      id: "dashboard_ecommerce",
      label: "Dashboard",
      link: "/dashboard",
      parentId: "dashboard",
      icon: "ri-dashboard-2-line",

      isActive: false,
      click: function (e) {
        e.preventDefault();
        // setIsEcommerce(!isEcommerce);
        changeMenu("dashboard_ecommerce")
      },
    },
    //   ],
    // },

    // ---------------------------category Management-----------------------------

    {
      id: "apps_ecommerce_600",
      label: "Category Management ",
      icon: " ri-order-play-fill",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_600");
      },
      subItems: [
        {
          id: "apps_ecommerce_26",
          label: "Category",
          link: ROUTES.CATEGORY_PAGE,
          isActive: false,
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_26");
          },
        },
        {
          id: "apps_ecommerce_27",
          label: "Measurement",
          link: ROUTES.MEASUREMENT_PAGE,
          isActive: false,
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_27");
          },
        },
      ]
    },

    //---------------------------- customer -------------------------------

    {
      id: "apps_ecommerce_601",
      label: "Customer Management ",
      icon: "  ri-account-circle-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_601");
      },
      subItems: [
        {
          id: "apps_ecommerce_28",
          label: "Customer",
          link: ROUTES.CUSTOMER_PAGE,
          isActive: false,
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_28");
          },
        },
        {
          id: "apps_ecommerce_29",
          label: "Customer Payment",
          link: ROUTES.CUSTOMER_PAYMENT,
          isActive: false,
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_29");
          },
        },
      ]
    },


    // ----------------------------order-----------------------
    {
      id: "apps_ecommerce_602",
      label: "Order Management ",
      icon: "ri-shopping-cart-line",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_602");
      },
      subItems: [
        {
          id: "apps_ecommerce_30",
          label: "Order",
          link: ROUTES.OREDER_PAGE,
          isActive: false,
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_30");
          },
        },
        {
          id: "apps_ecommerce_31",
          label: "Add New Order",
          link: ROUTES.ADD_NEW_ORDER,
          isActive: false,
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_31");
          },
        },
        {
          id: "apps_ecommerce_32",
          label: "Delivery Order Reminder",
          link: ROUTES.DELIVERY_ORDER_REMINDER,
          icon: " ri-calendar-2-line",
          isActive: false,
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_32");
          },
        },
        // {
        //   id: "apps_ecommerce_38",
        //   label: "Order Product",
        //   link: ROUTES.ORDER_PRODUCT,
        //   icon: " las la-hdd",
        //   isActive: false,
        //   click: function (e) {
        //     e.preventDefault();
        //     changeMenu("apps_ecommerce_38");
        //   },
        // },
      ]
    },

    // -------------------------------worker------------------------
    {
      id: "apps_ecommerce_603",
      label: "Worker Management ",
      icon: " bx bx-network-chart",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_603");
      },
      subItems: [
        {
          id: "apps_ecommerce_33",
          label: "Worker Details",
          link: ROUTES.WORKER_DETAILS,
          isActive: false,
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_33");
          },
        },
        {
          id: "apps_ecommerce_34",
          label: "Worker Task",
          link: ROUTES.WORKER_TASK,
          isActive: false,
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_34");
          },
        },
        {
          id: "apps_ecommerce_35",
          label: "Worker Payment",
          link: ROUTES.WORKER_PAYMENT,
          isActive: false,
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_35");
          },
        },
      ]
    },

    // --------------------------purchase---------------------------
    // {
    //   id: "apps_ecommerce_604",
    //   label: "Purchase Management ",
    // icon: " bx bx-purchase-tag",
    //   link: "/#",
    //   isActive: false,
    //   isChildItem: true,
    //   click: function (e) {
    //     e.preventDefault();
    //     changeMenu("apps_ecommerce_604");
    //   },
    //   subItems: [
    {
      id: "apps_ecommerce_35",
      label: "Purchase",
      link: ROUTES.PURCHASE,
      isActive: false,
      icon: " bx bx-purchase-tag",
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_35");
      },
    },
    //   ]
    // },





    // -----------------------report menu-----------------------

    {
      id: "apps_ecommerce_698",
      label: "Report Menu",
      icon: "bx bxs-report",
      link: "/#",
      isActive: false,
      isChildItem: true,
      click: function (e) {
        e.preventDefault();
        changeMenu("apps_ecommerce_698");
      },
      subItems: [
        {
          id: "apps_ecommerce_36",
          label: "Income",
          link: ROUTES.INCOME,
          parentId: "apps",
          isActive: false,
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_36");
          },
        },
        {
          id: "apps_ecommerce_37",
          label: "Worker Payment",
          link: ROUTES.WORK_PAYMENT,
          parentId: "apps",
          isActive: false,
          click: function (e) {
            e.preventDefault();
            changeMenu("apps_ecommerce_37");
          },
        },
      ],
    },
  ];

  const history = useNavigate();
  const location = useLocation();
  const path = location.pathname;


  const [menus, setMenus] = useState(initialMenusStates)

  const changeMenu = (menuId, field = 'id') => {
    let _menus = [...menus]

    _menus = _menus.map(menu => {
      menu.isActive = menu[field] == menuId ? !menu.isActive : false;
      if (menu.hasOwnProperty('subItems')) {
        let isMainMenuActive = false;
        let isSubMenuActive = false;

        menu.subItems = menu.subItems.map(subItem => {
          if (subItem[field] == menuId) {
            isMainMenuActive = true;
          }
          subItem.isActive = subItem[field] == menuId ? !subItem.isActive : false;

          if (menu.hasOwnProperty('childItems')) {
            subItem.childItems = subItem.childItems.map(childItem => {
              childItem.isActive = childItem[field] == menuId ? !childItem.isActive : false;
              if (childItem[field] == menuId) {
                isMainMenuActive = true;
                isSubMenuActive = true;
              }

              return childItem;
            })
          }

          if (isSubMenuActive) {
            subItem.isActive = isSubMenuActive;
          }
          return subItem;
        })

        if (isMainMenuActive) {
          menu.isActive = isMainMenuActive;
        }
        return menu;
      }

      return menu;
    })

    setMenus([..._menus])
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const initMenu = () => {
      // const pathName = process.env.PUBLIC_URL + path;
      // const ul = document.getElementById("navbar-nav");
      // const items = ul.getElementsByTagName("a");
      // let itemsArray = [...items]; // converts NodeList to Array
      // // removeActivation(itemsArray);
      // let matchingMenuItem = itemsArray.find((x) => {
      //     return x.pathname === pathName;
      // });
      // if (matchingMenuItem) {
      //     // activateParentDropdown(matchingMenuItem);
      // }
      changeMenu(path, 'link')
    };

    if (!HELPER.isEmpty(menus)) {
      initMenu();
    }

  }, [path]);


  return <React.Fragment>{menus}</React.Fragment>;
};
export default Navdata;
