/*------------------ Common Routes ------------------*/
export const HOME = "/";
export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const DASHBOARD = "/dashboard";

/*------------------ Category Page -----------------  */

export const CATEGORY_PAGE = "/category"
export const MEASUREMENT_PAGE = "/measurement"
export const CUSTOMER_PAGE = "/customer"
export const OREDER_PAGE = "/customer/order"
export const ADD_NEW_ORDER = "/customer/my-order"
export const EDIT_NEW_ORDER = "/customer/my-order/edit-order"
export const EDIT_CUSTOMER = "/customer/edit"
export const DELIVERY_ORDER_REMINDER = "/delivery-order-reminder"
export const CUSTOMER_PAYMENT = "/customer-payment"
export const WORKER_DETAILS = "/worker-details"
export const WORKER_TASK = "/worker-task"
export const WORKER_PAYMENT = "/worker-payment"
export const PURCHASE = "/purchase"
export const INCOME = "/income"
export const WORK_PAYMENT = "/workerPayment"
export const PRINT_MEASUREMENT = "/print-measurement"
export const CHEST_PAGE = "/chest-page"
export const ORDER_PRODUCT = "/order-product"
export const BILL_PAGE = "/bill"


export const filterRoute = (route) => {
  return route.substring(1);
};
