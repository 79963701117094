import appConfig from './appConfig'

const apiConfig = {
    baseURL: (
        appConfig.host + "/" +
        appConfig.prefix + "/" +
        appConfig.version + "/"
    ).replace(/([^:]\/)\/+/g, "$1"),

    // auth apis
    login: 'login',
    changePassword: "customer/change_password/:login_id",
    category: "category",
    category_list: "list/category_list",
    measurement: "measurement",
    editMeasurement: "measurement/:measurement_id",
    customer: "customer",
    editcustomerMeasuement:"customer/measurements",
    worker: "worker",
    order: "order",
    editOrder: "order/:order_id",
    getOrder: "order/get_measurement",
    order_list: "order/:id",
    chest: "chest_details",
    workerList: "list/worker_list",
    purchase: "purchase",
    purchesePayment: "purchase_payment",
    workerPayment: "worker_payment",
    chest: 'chest_details',
    delivery_order_reminder: "order/delivery_order_reminder",
    orderProduct: "order_product",
    customerList: "list/customer_list",
    statusChange: "order_product/:order_product_id",
    orderPayment: "order/:order_id/payment",
    income: "order/income",
    customerPayment: "order/customer_payment_data",
    billNo: "order/bill",
    customerMeasuement: "order/customer_measurement/:order_id",
    getCompleteTask: "delivery/find_completed_order/:order_id",
    delivery: "delivery",
    deliveryDataGet: "delivery/:id",
    order_category_list: "list/order_category_list",
    user: 'user',
    getUser: "/user",
    editUser: "/:user_id",
    pendingData: "worker/assign",
    workerAssignTask: "worker/worker_assign_task",
    orderImageDelete: "order/image/:order_image_id",
    completeAndPendingOrder: "order_product/pending_completed_order",
    dashboardCounts: "dashboard/get_counts",
    dashboardPendingOrder: "dashboard/get_pending_orders",
    dashboardPendingDelivery: "dashboard/get_pending_delivery_orders",
    workerAssign: "order_product/worker_assign_task",
    categoryWisePendingOrder: "dashboard/get_category_wise_pending"
};

export default apiConfig;
