import { createSlice } from "@reduxjs/toolkit";
import { appConfig } from "./../../configs";
import defaultState from "./../../constant/defaultState";
import { toaster } from "../../services/helper";

export const authSlice = createSlice({
  name: "auth",
  initialState: defaultState.toolkit.auth,
  reducers: {
    handleLogin: (state, action) => {
      let tempState = {
        ...state,
        ...action.payload,
      }

      window.localStorage.setItem(appConfig.localStorage.token, action.payload.token);
      return tempState
    },
    handleLogout: (state, action) => {
      window.localStorage.removeItem(appConfig.localStorage.token);
      window.location.href = "/"
      return { ...defaultState.toolkit.auth }
    },
  },
});

export default authSlice.reducer;
export const {
  handleLogin,
  handleLogout,
} = authSlice.actions;
