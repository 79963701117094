import axios from "axios";
import { apiConfig, appConfig } from "./../configs";
import * as HELPER from "./helper";
import { handleLogout } from "../slices/toolkit/auth";
import { store } from "../slices";

const instance = axios.create({
    baseURL: apiConfig.baseURL,
    headers: {
        "Content-Type": "application/json",
    },
});

// handle errors
instance.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem(appConfig.localStorage.token);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        if (config?.data?.is_public_url || config?.params?.is_public_url) {
            config.baseURL = apiConfig.publicURL;
            delete config?.data?.is_public_url;
            delete config?.params?.is_public_url;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

// handle errors
instance.interceptors.response.use(
    (response) => {
        return response.data.success ? response.data.data : response.data.error;
    },
    (error) => {
        // in the case, server is stoped
        if (error.code == "ERR_NETWORK") {
            HELPER.toaster.error("Something went wrong, Please try after sometimes.");
        }

        if (error?.response && error?.response?.status == 401) {
            if (true == HELPER.isAuth()) {
                localStorage.clear();
                store.dispatch(handleLogout());
                store.dispatch({
                    type: "USER_LOGOUT",
                });
            }
        }

        return Promise.reject({
            errors:
                error?.response && error.response?.data?.error
                    ? error.response?.data?.error
                    : { message: ["Somthing went wrong."] },
            status:
                error?.response && error.response?.data?.status
                    ? error.response?.data?.status
                    : 501,
        });
    }
);

const post = (url, data = {}, headers = {}) =>
    instance.post(url, data, headers);

const destroy = (url, data = {}) => instance.delete(url, { data });

const get = (url, params = {}, other = {}) =>
    instance.get(url, {
        params,
        ...other,
    });

const put = (url, data, headers = {}) => instance.put(url, data, headers);

export default {
    post,
    destroy,
    get,
    put,
};
