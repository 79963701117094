import React, { useState } from 'react'
import { Button, CardBody, Form, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Validators from '../../Components/validations/Validator';
import Textinput from '../../Components/Common/Textinput';
import ReactButton from '../../Components/Common/ReactButton';
import { ROUTES, apiConfig } from '../../configs';
import { useNavigate } from 'react-router-dom';
import { toaster } from '../../services/helper';
import { useSelector } from 'react-redux';
import { API, HELPER } from '../../services';

const initialValue = {
    old_password: "",
    new_password: "",
    confirm_password: ""
}

const ChangePassword = ({ changePassword, toggleChangePassword }) => {
    const navigate = useNavigate()
    const [formState, setFormState] = useState({ ...initialValue })
    const auth = useSelector((state) => state?.auth?.user?.id)
    const rules = {
        old_password: "required",
        new_password: "required",
        confirm_password: "required",
    };

    const onChange = ({ target: { name, value } }) => {
        setFormState((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const handleChangePassword = () => {
        API.put(apiConfig.changePassword.replace(":login_id", auth), formState)
            .then((res) => {
                HELPER.toaster.success(res)
                toggleChangePassword()
            }).catch((err) => {
                if (err?.errors?.message) {
                    HELPER.toaster.error(err?.errors?.message);
                } else if (err.errors) {
                    Object.values(err.errors).map((e) => HELPER.toaster.error(e));
                } else {
                    HELPER.toaster.error(err);
                }
            })
    }
    return (
        <Modal
            id="showModal"
            isOpen={changePassword}
            toggle={toggleChangePassword}
            backdrop={"static"}
            centered
            className="modal-md">
            <ModalHeader className="bg-light p-3" toggle={toggleChangePassword}>Change Password</ModalHeader>
            <ModalBody>
                <Validators formData={formState} rules={rules}>
                    {({ onSubmit, errors }) => {
                        return (
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    onSubmit(handleChangePassword);
                                }}
                                action="#"
                            >
                                <CardBody>
                                    <div>
                                        <Textinput
                                            type={"password"}
                                            label={"Old Password"}
                                            value={formState?.old_password}
                                            name="old_password"
                                            error={errors?.old_password}
                                            onChange={onChange}
                                            placeholder="Enter Old Password"
                                        />
                                    </div>
                                    <div>
                                        <Textinput
                                            type={"password"}
                                            label={"New Password"}
                                            value={formState?.new_password}
                                            name="new_password"
                                            error={errors?.new_password}
                                            onChange={onChange}
                                            placeholder="Enter New Password"
                                        />
                                    </div>
                                    <div>
                                        <Textinput
                                            type={"password"}
                                            label={"Confirm Password"}
                                            value={formState?.confirm_password}
                                            name="confirm_password"
                                            error={errors?.confirm_password}
                                            onChange={onChange}
                                            placeholder="Enter Condirm Password"
                                        />
                                    </div>
                                    <div className="text-end d-flex float-end">
                                        <ReactButton
                                            color="success"
                                            className="btn btn-success w-100"
                                            type="submit"
                                        >
                                            Submit
                                        </ReactButton>
                                    </div>
                                </CardBody>
                            </Form>
                        );
                    }}
                </Validators>
            </ModalBody>
        </Modal>
    )
}

export default ChangePassword