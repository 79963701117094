import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
// Import Data
import navdata from "../LayoutMenuData";

import withRouter from "../../Components/Common/withRouter";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";

const VerticalLayout = (props) => {
    const navData = navdata().props.children;
    const path = props.router.location.pathname;

    /*
   layout settings
   */

    const selectLayoutState = (state) => state.Layout;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            leftsidbarSizeType: layout.leftsidbarSizeType,
            sidebarVisibilitytype: layout.sidebarVisibilitytype,
            layoutType: layout.layoutType,
        })
    );
    // Inside your component
    const { leftsidbarSizeType, sidebarVisibilitytype, layoutType } = useSelector(
        selectLayoutProperties
    );

    //vertical and semibox resize events
    const resizeSidebarMenu = useCallback(() => {
        var windowSize = document.documentElement.clientWidth;
        if (windowSize >= 1025) {
            if (document.documentElement.getAttribute("data-layout") === "vertical") {
                document.documentElement.setAttribute(
                    "data-sidebar-size",
                    leftsidbarSizeType
                );
            }
            if (document.documentElement.getAttribute("data-layout") === "semibox") {
                document.documentElement.setAttribute(
                    "data-sidebar-size",
                    leftsidbarSizeType
                );
            }
            var hamburgerIcon = document.querySelector(".hamburger-icon");
            if (
                (sidebarVisibilitytype === "show" ||
                    layoutType === "vertical" ||
                    layoutType === "twocolumn") &&
                document.querySelector(".hamburger-icon")
            ) {
                if (hamburgerIcon !== null) {
                    hamburgerIcon.classList.remove("open");
                }
            } else {
                if (hamburgerIcon !== null) {
                    hamburgerIcon.classList.add("open");
                }
            }
        } else if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove("twocolumn-panel");
            if (document.documentElement.getAttribute("data-layout") === "vertical") {
                document.documentElement.setAttribute("data-sidebar-size", "sm");
            }
            if (document.documentElement.getAttribute("data-layout") === "semibox") {
                document.documentElement.setAttribute("data-sidebar-size", "sm");
            }
            if (document.querySelector(".hamburger-icon")) {
                document.querySelector(".hamburger-icon").classList.add("open");
            }
        } else if (windowSize <= 767) {
            document.body.classList.remove("vertical-sidebar-enable");
            if (
                document.documentElement.getAttribute("data-layout") !== "horizontal"
            ) {
                document.documentElement.setAttribute("data-sidebar-size", "lg");
            }
            if (document.querySelector(".hamburger-icon")) {
                document.querySelector(".hamburger-icon").classList.add("open");
            }
        }
    }, [leftsidbarSizeType, sidebarVisibilitytype, layoutType]);

    useEffect(() => {
        window.addEventListener("resize", resizeSidebarMenu, true);
    }, [resizeSidebarMenu]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [path, props.layoutType]);

    return (
        <React.Fragment>
            {/* menu Items */}
            {(navData || []).map((item, key) => {
                return (
                    <React.Fragment key={key}>
                        {/* Main Header */}
                        {item?.isHeader ? (
                            <li className="menu-title" style={{ color: "#ffffff73" }}>
                                <span data-key="t-menu" style={{ paddingBottom: "5px " }}>
                                    {item.label}
                                </span>
                            </li>
                        ) : item.subItems ? (
                            <li className="nav-item">
                                <Link
                                    onClick={item.click}
                                    className={`nav-link menu-link ${item.isActive ? "active" : ""
                                        }`}
                                    to={item.link ? item.link : "/#"}
                                    data-bs-toggle="collapse"
                                >
                                    <i className={item.icon} style={{ fontSize: "17px" }}></i>
                                    <span data-key="t-apps" style={{ fontSize: "14px" }}>
                                        {item.label}
                                    </span>
                                    {item.badgeName ? (
                                        <span
                                            className={"badge badge-pill bg-" + item.badgeColor}
                                            data-key="t-new"
                                        >
                                            {item.badgeName}
                                        </span>
                                    ) : null}
                                </Link>
                                <Collapse
                                    className="menu-dropdown"
                                    isOpen={item.isActive}
                                    id="sidebarApps"
                                >
                                    <ul className="nav nav-sm flex-column test">
                                        {/* subItms  */}
                                        {item.subItems &&
                                            (item.subItems || []).map((subItem, key) => (
                                                <React.Fragment key={key}>
                                                    {!subItem.isChildItem ? (
                                                        <li className="nav-item">
                                                            <Link
                                                                to={subItem.link ? subItem.link : "/#"}
                                                                className={`nav-link ${subItem.isActive ? "active" : ""
                                                                    }`}
                                                            >
                                                                {subItem.label}
                                                                {subItem.badgeName ? (
                                                                    <span
                                                                        className={
                                                                            "badge badge-pill bg-" +
                                                                            subItem.badgeColor
                                                                        }
                                                                        data-key="t-new"
                                                                    >
                                                                        {subItem.badgeName}
                                                                    </span>
                                                                ) : null}
                                                            </Link>
                                                        </li>
                                                    ) : (
                                                        <li className="nav-item">
                                                            <Link
                                                                onClick={subItem.click}
                                                                className={`nav-link ${subItem.isActive ? "active" : ""
                                                                    }`}
                                                                to="/#"
                                                                data-bs-toggle="collapse"
                                                            >
                                                                {subItem.label}
                                                                {subItem.badgeName ? (
                                                                    <span
                                                                        className={
                                                                            "badge badge-pill bg-" +
                                                                            subItem.badgeColor
                                                                        }
                                                                        data-key="t-new"
                                                                    >
                                                                        {subItem.badgeName}
                                                                    </span>
                                                                ) : null}
                                                            </Link>
                                                            <Collapse
                                                                className="menu-dropdown"
                                                                isOpen={subItem.isActive}
                                                                id="sidebarEcommerce"
                                                            >
                                                                <ul className="nav nav-sm flex-column">
                                                                    {/* child subItms  */}
                                                                    {subItem.childItems &&
                                                                        (subItem.childItems || []).map(
                                                                            (childItem, key) => (
                                                                                <React.Fragment key={key}>
                                                                                    {!childItem.childItems ? (
                                                                                        <li className="nav-item">
                                                                                            <Link
                                                                                                to={
                                                                                                    childItem.link
                                                                                                        ? childItem.link
                                                                                                        : "/#"
                                                                                                }
                                                                                                className={`nav-link ${childItem.isActive
                                                                                                        ? "active"
                                                                                                        : ""
                                                                                                    }`}
                                                                                            >
                                                                                                {childItem.label}
                                                                                            </Link>
                                                                                        </li>
                                                                                    ) : (
                                                                                        <li className="nav-item">
                                                                                            <Link
                                                                                                to="/#"
                                                                                                className={`nav-link ${childItem.isActive
                                                                                                        ? "active"
                                                                                                        : ""
                                                                                                    }`}
                                                                                                onClick={childItem.click}
                                                                                                data-bs-toggle="collapse"
                                                                                            >
                                                                                                {childItem.label}
                                                                                            </Link>
                                                                                            <Collapse
                                                                                                className="menu-dropdown"
                                                                                                isOpen={childItem.isActive}
                                                                                                id="sidebaremailTemplates"
                                                                                            >
                                                                                                <ul className="nav nav-sm flex-column">
                                                                                                    {childItem.childItems.map(
                                                                                                        (subChildItem, key) => (
                                                                                                            <li
                                                                                                                className="nav-item"
                                                                                                                key={key}
                                                                                                            >
                                                                                                                <Link
                                                                                                                    to={subChildItem.link}
                                                                                                                    className={`nav-link ${childItem.isActive
                                                                                                                            ? "active"
                                                                                                                            : ""
                                                                                                                        }`}
                                                                                                                    data-key="t-basic-action"
                                                                                                                >
                                                                                                                    {subChildItem.label}{" "}
                                                                                                                </Link>
                                                                                                            </li>
                                                                                                        )
                                                                                                    )}
                                                                                                </ul>
                                                                                            </Collapse>
                                                                                        </li>
                                                                                    )}
                                                                                </React.Fragment>
                                                                            )
                                                                        )}
                                                                </ul>
                                                            </Collapse>
                                                        </li>
                                                    )}
                                                </React.Fragment>
                                            ))}
                                    </ul>
                                </Collapse>
                            </li>
                        ) : (
                            <li className="nav-item">
                                <Link
                                    className="nav-link menu-link"
                                    to={item.link ? item.link : "/#"}
                                >
                                    <i className={item.icon}></i> <span>{item.label}</span>
                                    {item.badgeName ? (
                                        <span
                                            className={"badge badge-pill bg-" + item.badgeColor}
                                            data-key="t-new"
                                        >
                                            {item.badgeName}
                                        </span>
                                    ) : null}
                                </Link>
                            </li>
                        )}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

VerticalLayout.propTypes = {
    location: PropTypes.object,
    t: PropTypes.any,
};

export default withRouter(VerticalLayout);
