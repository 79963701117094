import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../configs";
import { filterRoute } from "../configs/routes";
import TailorHomePage from "../pages/Authentication/TailorHome";
// import ChestPage from "../pages/ChestDetail/ChestPage";


// Authentication Page
const Login = lazy(() => import("../pages/Authentication/Login"));
const DashboardEcommerce = lazy(() => import("../pages/DashboardEcommerce"));
const ForgetPasswordPage = lazy(() =>
  import("../pages/Authentication/ForgetPassword")
);

const CategoryPage = lazy(() => import("../pages/Category/CategoryPage"));
const MeasurementPage = lazy(() => import("../pages/Measurement/MeasurementPage"));
const CusromerPage = lazy(() => import("../pages/Customer/CustomerPage"));
const OrderPage = lazy(() => import("../pages/Order/OrderPage"));
const AddNewOrderPage = lazy(() => import("../pages/AddNewOrder/AddNewOrderPage"));
const EditNewOrderPage = lazy(() => import("../pages/AddNewOrder/EditNewOrderPage"));
const EditCustomer = lazy(() => import("../pages/Customer/EditCustomer"));
const DeliveryOrderReminder = lazy(() => import("../pages/DeliveryOrderReminder/DeliveryOrderReminder"));
const CustomerPayment = lazy(() => import("../pages/CustomerPayment/CustomerPayment"));
const WorkerDetails = lazy(() => import("../pages/WorkerDetails/WorkerDetails"));
const WorkTask = lazy(() => import("../pages/WorkerTask/WorkerTask"));
const WorkerPayment = lazy(() => import("./../pages/WorkerPayment/WorkerPayment"));
const Purchase = lazy(() => import("./../pages/Purchase/PurchasePage"));
const Income = lazy(() => import("./../pages/Income/IncomePage"));
const WorkePayment = lazy(() => import("./../pages/WorkePayment/WorkePayment"));
const PrintMeasurement = lazy(() => import("./../pages/DashboardEcommerce/PrintMeasurement"));
const BillPage = lazy(() => import("./../pages/BillPage/BillPage"));
// const ChestPage = lazy(() => import("./../pages/ChestDetail/ChestPage"));
const OrderProductPage = lazy(() => import("./../pages/OrderProduct/OrderProductPage"));



const authProtectedRoutes = [
  { path: filterRoute(ROUTES.DASHBOARD), component: <DashboardEcommerce /> },
  { path: "/index", component: <DashboardEcommerce /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to={filterRoute(ROUTES.DASHBOARD)} />,
  },
  { path: "*", component: <Navigate to={filterRoute(ROUTES.DASHBOARD)} /> },

  // ------------------------- category ------------------------
  {
    path: filterRoute(ROUTES.CATEGORY_PAGE),
    component: <CategoryPage />,
  },

  // ------------------------- Measurement ------------------------
  {
    path: filterRoute(ROUTES.MEASUREMENT_PAGE),
    component: <MeasurementPage />,
  },

  // ------------------------- customer ------------------------
  {
    path: filterRoute(ROUTES.CUSTOMER_PAGE),
    component: <CusromerPage />,
  },

  // ------------------------- order ------------------------
  {
    path: filterRoute(ROUTES.OREDER_PAGE),
    component: <OrderPage />,
  },

  // ------------------------- Add-new-order ------------------------
  {
    path: filterRoute(ROUTES.ADD_NEW_ORDER),
    component: <AddNewOrderPage />,
  },
  // ------------------------- Add-new-order ------------------------
  {
    path: filterRoute(`${ROUTES.EDIT_NEW_ORDER}/:id`),
    component: <EditNewOrderPage />,
  },

  // ------------------------- bill page ------------------------
  {
    path: filterRoute(`${ROUTES.BILL_PAGE}/:bill`),
    component: <BillPage />,
  },

  {
    path: filterRoute(ROUTES.EDIT_CUSTOMER),
    component: <EditCustomer />,
  },

  // ------------------------- delivery order ------------------------
  {
    path: filterRoute(ROUTES.DELIVERY_ORDER_REMINDER),
    component: <DeliveryOrderReminder />,
  },

  // ------------------------- customer-payment ------------------------
  {
    path: filterRoute(ROUTES.CUSTOMER_PAYMENT),
    component: <CustomerPayment />,
  },

  // ------------------------- worker-details ------------------------
  {
    path: filterRoute(ROUTES.WORKER_DETAILS),
    component: <WorkerDetails />,
  },

  // ------------------------- worker-task ------------------------
  {
    path: filterRoute(ROUTES.WORKER_TASK),
    component: <WorkTask />,
  },

  // ------------------------- worker-payment ------------------------
  {
    path: filterRoute(ROUTES.WORKER_PAYMENT),
    component: <WorkerPayment />,
  },

  // ------------------------- Purchase ------------------------
  {
    path: filterRoute(ROUTES.PURCHASE),
    component: <Purchase />,
  },
  // ------------------------- Income ------------------------
  {
    path: filterRoute(ROUTES.INCOME),
    component: <Income />,
  },
  // ------------------------- Work-payment ------------------------
  {
    path: filterRoute(ROUTES.WORK_PAYMENT),
    component: <WorkePayment />,
  },

  // ------------------------- Print-measurement ------------------------
  {
    path: filterRoute(`${ROUTES.PRINT_MEASUREMENT}/:order_id`),
    component: <PrintMeasurement />,
  },

  // ------------------------- Order-Product ------------------------
  {
    path: filterRoute(ROUTES.ORDER_PRODUCT),
    component: <OrderProductPage />,
  },

  // ------------------------- Chest-Page ------------------------
  // {
  //   path: filterRoute(ROUTES.CHEST_PAGE),
  //   component: <ChestPage />,
  // },

];

const publicRoutes = [
  // Authentication Page
  { path: filterRoute(ROUTES.HOME), component: <TailorHomePage /> },
  { path: filterRoute(ROUTES.LOGIN), component: <Login /> },
  {
    path: filterRoute(ROUTES.FORGOT_PASSWORD),
    component: <ForgetPasswordPage />,
  },


];

export { authProtectedRoutes, publicRoutes };
