import React, { useEffect, useState } from "react";
import { CardBody, Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import Validators from "../../Components/validations/Validator";
import Textinput from "../../Components/Common/Textinput";
import ReactButton from "../../Components/Common/ReactButton";
import { ROUTES } from "../../configs";
import { useNavigate } from "react-router-dom";
import { toaster } from "../../services/helper";

const BillMaster = ({ billModal, toggleBill }) => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState({ bill_no: "" });
  const [orderId, setOrderId] = useState(null);
  const rules = {
    bill_no: "required",
  };

  const handelBillSearch = () => {
    if (formState?.bill_no == "") {
      toaster.error("Enter Bill Number");
    } else {
      let orderData = {
        order_id: orderId,
      };
      navigate(`${ROUTES.BILL_PAGE}/${formState.bill_no}`, {
        state: { orderData },
      });
      toggleBill();
    }
  };

  useEffect(() => {
    setOrderId(formState?.bill_no);
  }, [formState?.bill_no]);

  // --------------- Start Time Focus --------------
  useEffect(() => {
    window?.document?.getElementById("focusBillInputId")?.focus();
  }, [orderId]);

  return (
    <Modal
      id="showModal"
      isOpen={billModal}
      toggle={toggleBill}
      backdrop={"static"}
      centered
      className="modal-md"
    >
      <ModalHeader className="bg-light p-3" toggle={toggleBill}>
        Bill Master
      </ModalHeader>
      <ModalBody>
        <Validators formData={formState} rules={rules}>
          {({ onSubmit, errors }) => {
            return (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit(() => {
                    handelBillSearch();
                  });
                }}
                action="#"
              >
                <CardBody>
                  <div>
                    <Textinput
                      id="focusBillInputId"
                      type={"number"}
                      label={"Bill No"}
                      value={formState?.bill_no}
                      name="bill_no"
                      error={errors?.bill_no}
                      onChange={(e) => {
                        setFormState({
                          ...formState,
                          bill_no: e.target.value,
                        });
                      }}
                      placeholder="Enter Bill No"
                    />
                  </div>
                  <div className="text-end d-flex float-end">
                    <ReactButton
                      // loader={isLoader}
                      color="success"
                      className="btn btn-success w-100 "
                      type="submit"
                    >
                      Search
                    </ReactButton>
                  </div>
                </CardBody>
              </Form>
            );
          }}
        </Validators>
      </ModalBody>
    </Modal>
  );
};

export default BillMaster;
