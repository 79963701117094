import React, { useState } from "react";
import { Input, Label, FormFeedback } from 'reactstrap';
import { HELPER } from "../../services";
import ValidationMessages from "../validations/ValidationMessages";

const Textinput = ({
  type,
  label,
  placeholder = "",
  className = "",
  name,
  readonly,
  value,
  error,
  icon,
  disabled,
  id,
  onChange,
  ...rest
}) => {

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="mb-3">
      {label && <Label htmlFor={label} className="form-label">{label}</Label>}

      <div className="position-relative auth-pass-inputgroup mb-3">
        <Input
          type={type === "password" && open === true ? "text" : type}
          className="form-control"
          {...rest}
          placeholder={placeholder}
          readOnly={readonly}
          value={value ? value : ""}
          disabled={disabled}
          id={id}
          onChange={onChange}
          name={name}
          invalid={error ? true : false}
        />
        <ValidationMessages
          errors={error}
          label={label}
        />

        {type === "password" && <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={handleOpen}><i className="ri-eye-fill align-middle"></i></button>}
      </div>

    </div>
  );
};

export default Textinput;
