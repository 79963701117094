import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
// import avatar1 from "../../assets/images/users/avatar-1.jpg";
import fav from "../../assets/images/users/fav.png";
import { store } from '../../slices';
import { handleLogout } from '../../slices/toolkit/auth';
import BillMaster from './BillMaster';
import ChangePassword from './ChangePassword';
import { useSelector } from 'react-redux';
import ProfilePage from './ProfilePage';
import { API, HELPER } from '../../services';
import noimg from '../../assets/images/users/noimg.jpg';
import { apiConfig } from '../../configs';

const ProfileDropdown = () => {

    const [billModal, setBillModal] = useState(false)
    const [profileModal, setProfileModal] = useState(false)
    const [changePassword, setChangePassword] = useState(false)
    const [initialState, setInitialState] = useState([])
    const [profileData, setProfileData] = useState(null)

    const auth = useSelector((state) => state.auth);

    const toggleProfile = useCallback(() => {
        setProfileModal(false)
    }, [profileModal])

    const toggleBill = useCallback(() => {
        setBillModal(false)
    }, [billModal])

    const toggleChangePassword = useCallback(() => {
        setChangePassword(false)
    }, [changePassword])

    const getUser = () => {
        API.get(apiConfig.getUser)
            .then((res) => {
                setInitialState(res)
            })
    }

    useEffect(() => {
        getUser()
    }, [])

    useEffect(() => {
        initialState.map((item) => (setProfileData(item)))
    }, [initialState])
    // const profiledropdownData = createSelector(
    //     (state) => state.Profile.user,
    //     (user) => user
    //   );
    // // Inside your component
    // const user = useSelector(profiledropdownData);

    const [userName, setUserName] = useState("Admin");

    const handleProfile = () => {
        setProfileModal(true)
    }

    const handleBill = () => {
        setBillModal(true)
    }


    const handleChangePassword = () => {
        setChangePassword(true)
    }

    // useEffect(() => {
    //     if (sessionStorage.getItem("authUser")) {
    //         const obj = JSON.parse(sessionStorage.getItem("authUser"));
    //         setUserName(process.env.REACT_APP_DEFAULTAUTH === "fake" ? obj.username === undefined ? user.first_name ? user.first_name : obj.data.first_name : "Admin" || "Admin" :
    //             process.env.REACT_APP_DEFAULTAUTH === "firebase" ? obj.email && obj.email : "Admin"
    //         );
    //     }
    // }, [userName, user]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={HELPER.getImageUrl(profileData?.logo)}
                            alt="Header Avatar"
                            onError={(e) => {
                                e.target.src = noimg;
                            }} />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text"> {auth && auth.user && auth.user.userName}</span>
                            {/* <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">Founder</span> */}
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {auth && auth.user && auth.user.userName}!</h6>
                    <DropdownItem className='p-0'>
                        <Link to={process.env.PUBLIC_URL + "/profile"} className="dropdown-item" onClick={() => { handleProfile() }}>
                            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Profile</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link className="dropdown-item" onClick={() => { handleBill() }}>
                            <i className="ri-bill-line text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Bill Master</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link className="dropdown-item" onClick={() => { handleChangePassword() }}>
                            <i className="ri-lock-unlock-line text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Change Password</span>
                        </Link>
                    </DropdownItem>
                    <DropdownItem className='p-0'>
                        <Link className="dropdown-item" onClick={() => store.dispatch(handleLogout())}>
                            <i
                                className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                                    className="align-middle" data-key="t-logout">Logout</span>
                        </Link>

                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            {
                profileModal && <ProfilePage
                    profileModal={profileModal}
                    toggleProfile={toggleProfile}
                />
            }
            {
                billModal && <BillMaster
                    billModal={billModal}
                    toggleBill={toggleBill}
                />
            }
            {
                changePassword && <ChangePassword
                    changePassword={changePassword}
                    toggleChangePassword={toggleChangePassword}
                />
            }
        </React.Fragment>

    );
};

export default ProfileDropdown;