import ValidationMessages from "../validations/ValidationMessages";
import { Label } from 'reactstrap';
const Textarea = ({
    type,
    label,
    placeholder = "",
    className = "",
    name,
    readonly,
    value,
    error,
    icon,
    disabled,
    id,
    onChange,
    cols,
    row = 3,
    ...rest
}) => {
    return (
        <div className="mb-3">
            {label && <Label htmlFor={label} className="form-label">{label}</Label>}
            <div className="position-relative auth-pass-inputgroup mb-3">
                <textarea
                    {...rest}
                    className={`form-control ${className}`}
                    placeholder={placeholder}
                    readOnly={readonly}
                    disabled={disabled}
                    id={id}
                    cols={cols}
                    rows={row}
                    name={name}
                    onChange={onChange}
                    value={value === (undefined || null) ? "" : value}
                ></textarea>
                <ValidationMessages
                    errors={error}
                    label={label}
                />
            </div>
        </div>
    );
};
export default Textarea;