import React, { useEffect, useState } from 'react'
import Validators from '../validations/Validator';
import { ModalHeader, Modal, ModalBody, Form, CardBody, Label, Row, Col, Input } from 'reactstrap';
import Textinput from '../../Components/Common/Textinput';
import { API, HELPER } from '../../services';
import Textarea from '../../Components/Common/Textarea';
import ReactButton from '../../Components/Common/ReactButton';
import { apiConfig } from '../../configs';
import noimg from '../../assets/images/users/noimg.jpg'



const initialValues = {
    mobile_no: "",
    logo: "",
    address: ""
}

const ProfilePage = ({ profileModal, toggleProfile }) => {
    //  -------------formState --------------
    const [formState, setFormState] = useState({
        ...initialValues,
    });
    const [initialState, setInitialState] = useState([])
    const [imageUrl, setImageUrl] = useState("");
    const [profileData, setProfileData] = useState(null)

    const rules = {
        mobile_no: "required",
        logo: "required",
        address: "required"
    };

    const onChange = ({ target: { value, name } }) => {
        if (
            (name === "mobile_no" && value.length > 10)
        ) {
            return;
        }
        setFormState((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const getUser = () => {
        API.get(apiConfig.getUser)
            .then((res) => {
                setInitialState(res)
            })
    }

    useEffect(() => {
        getUser()
    }, [])

    useEffect(() => {
        initialState.map((item) => (setProfileData(item)))
    }, [initialState])


    useEffect(() => {
        if (profileData !== null) {
            setFormState((prevProps) => {
                return {
                    ...prevProps,
                    mobile_no: profileData?.mobile_no,
                    logo: profileData?.logo,
                    address: profileData?.address
                };
            });
            setImageUrl(HELPER.getImageUrl(profileData?.logo));
        }
    }, [profileData]);


    // ---------Conver Image Objectbase64-----------------
    const convertImgObjToBase64 = async (obj) => {
        HELPER.imgObjToBase64(obj).then((res) => setImageUrl(res));
    };

    const handleProfile = (data) => {
        const formData = new FormData()
        formData.append("mobile_no", data.mobile_no);
        formData.append("logo", data.logo);
        formData.append("address", data.address);

        API.put(apiConfig.editUser.replace(":user_id", profileData?.login_id), formData, {
            headers: {
                "Content-Type": `multipart/form-data;`,
            }
        },)
            .then((res) => {
                HELPER.toaster.success(res)
                toggleProfile()
            }).catch((err) => {
                if (err?.errors?.message) {
                    HELPER.toaster.error(err?.errors?.message);
                } else if (err.errors) {
                    Object.values(err.errors).map((e) => HELPER.toaster.error(e));
                } else {
                    HELPER.toaster.error(err);
                }
            })
    }


    return (
        <Modal
            id="showModal"
            isOpen={profileModal}
            toggle={toggleProfile}
            backdrop={"static"}
            centered
            className="modal-md">
            <ModalHeader className="bg-light p-3" toggle={toggleProfile}>User Profile</ModalHeader>
            <ModalBody>
                <Validators formData={formState} rules={rules}>
                    {({ onSubmit, errors }) => {
                        return (
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    onSubmit(handleProfile);
                                }}
                                action="#"
                            >
                                <CardBody>
                                    <div>
                                        <Textinput
                                            label={"User Name"}
                                            value={profileData?.user_name}
                                            name="userName"
                                            // error={errors?.userName}
                                            placeholder="Enter User Name"
                                        />
                                        <Textinput
                                            type={"number"}
                                            label={"Mobile Number"}
                                            value={formState?.mobile_no}
                                            name="mobile_no"
                                            error={errors?.mobile_no}
                                            onChange={onChange}
                                            placeholder="Enter Mobile Number"
                                        />
                                        <div className="mb-3">
                                            <Row>
                                                <Label
                                                    // htmlFor="formFileMultiple"
                                                    className="form-label"
                                                >
                                                    Logo
                                                </Label>
                                                {
                                                    profileData !== null && (
                                                        <Col xl={2}>
                                                            <div>
                                                                <img
                                                                    className="modal-image-display-profile"
                                                                    src={imageUrl}
                                                                    alt=""
                                                                    onError={(e) => {
                                                                        e.target.src = noimg;
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                    )
                                                }

                                                <Col sm={9} className='profile-img' >
                                                    <Input
                                                        className="form-control"
                                                        type="file"
                                                        id="formFileMultiple8"
                                                        name="logo"
                                                        accept=".png, .jpg, .jpeg, .webp,.svg"
                                                        selectedfile={formState.logo}
                                                        onChange={(e) => {
                                                            const selectedFile = e.target.files[0];
                                                            if (selectedFile) {
                                                                setFormState((prevProps) => ({
                                                                    ...prevProps,
                                                                    logo: selectedFile,
                                                                }));
                                                                convertImgObjToBase64(selectedFile);
                                                            } else {
                                                                setFormState((prevProps) => ({
                                                                    ...prevProps,
                                                                    logo: "",
                                                                }));
                                                                setImageUrl("");
                                                            }
                                                        }}
                                                        multiple
                                                    />
                                                    {errors && errors?.image_name && (
                                                        <p className="text-error">Image Is required</p>
                                                    )}</Col>
                                            </Row>
                                        </div>
                                        <Textarea
                                            className={` form-control ${errors?.address ? " text-error-border" : ""}`}
                                            rows="3"
                                            name="address"
                                            value={formState?.address}
                                            onChange={onChange}
                                            placeholder="Enter Address"
                                            label={"Address"}
                                            error={errors?.address}
                                        />
                                    </div>
                                    <div className="text-end d-flex float-end">
                                        <ReactButton
                                            // loader={isLoader}
                                            color="success"
                                            className="btn btn-success w-100"
                                            type="submit"
                                        >
                                            Submit
                                        </ReactButton>
                                    </div>
                                </CardBody>
                            </Form>
                        );
                    }}
                </Validators>
            </ModalBody>
        </Modal >
    )
}

export default ProfilePage